import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import dayjs, {Dayjs} from "dayjs";


const excelFileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const excelFileExtension = ".xlsx";
const excelFileName = "순찰관리";


const data = [
    {
        name: "Jack",
        age: 30,
        region: "USA",
        occupation: "Lawyer",
    },
    {
        name: "Emily",
        age: 25,
        region: "Canada",
        occupation: "Engineer",
    },
    {
        name: "Sophia",
        age: 42,
        region: "UK",
        occupation: "Doctor",
    },
    {
        name: "Michael",
        age: 35,
        region: "Australia",
        occupation: "Teacher",
    },
    {
        name: "Emma",
        age: 28,
        region: "Germany",
        occupation: "Designer",
    },
];

class Excel {

    extract_date(datetime:string):string{
        let s = dayjs(datetime);
        // s.format("YYYY-MM-DD")
        return s.format("YYYY-MM-DD");
    }

    extract_time(datetime:string):string{
        let s = dayjs(datetime);
        // s.format("YYYY-MM-DD")
        return s.format("HH:mm");
    }
    async save(data:any[]){
        const ws = XLSX.utils.aoa_to_sheet([
            [`순찰기록`],
            // [``],
            [`일시`,`시간`,`순찰구분`,`장소`,`이름`, `부서`,`직책`],
        ]);

        // 그리고 내 데이터를 looping해서 보면 이름, 나이, 직업, 국적 이렇게 4 column이 준비되어있고,
        // 해당 column에 하나씩 추가
        data.map((v) => {
            XLSX.utils.sheet_add_aoa(ws, [[
                this.extract_date(v.action_date),
                this.extract_time(v.action_date),
                //v.phone,
                v.action,
                v.place,
                v.name,
                v.department,
                v.position
            ]], {
                origin: -1,
            });
            ws["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 },{ wpx: 100 },{ wpx: 100 },{ wpx: 100 }];
            return false;
        });

        // 마지막으로 wb에 이제 내가 만든 worksheet과 sheet name을 정해야 하는데,
        // sheet name은 영어를 사용. (만약 엑셀에 데이터가 안나오면 sheet name을 'data'로 변경해서 체크).
        const wb = {
            Sheets: { data: ws },
            SheetNames: ["data"],
        };

        // 여기서부턴 실제 엑셀파일 준비하는 곳
        const excelButter = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const excelFile = new Blob([excelButter], { type: excelFileType });
        // 여기에 1초의 간격을 두어서 혹시 데이터가 다 안 받아진 상태에서 엑셀이 준비되는걸 방지
        // 사실 이 아래 await new Promise... 이 코드는 그냥 safe를 위한거지 없어도 상관x
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // 파일세이버를 이용해 파일을 생성
        FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
    }

}

export {Excel}
