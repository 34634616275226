import React, {Component} from 'react';
import {makeAutoObservable} from "mobx";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {Space, Table} from "antd";
import {observer} from "mobx-react";
import {MQTT} from "../../common/MQTT";
import {WrapMessageAPI} from "../../common/HocMessage";
import './table.css'
import dayjs from "dayjs";
import {css} from 'styled-components/macro';
import Optional from "optional-js";

// import {} from ""

class Store {
    dataSource = [];


    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        let newVar = await apollo_client.query({
            query: gql`
                query {
                    reserversForDashboard {
                        name
                        reserve_date
                        enter
                        start
                        end
                    }
                }
            `,
            fetchPolicy: "no-cache"
        }).then(value => {
            /*let reserversForDashboard = value.data.reserversForDashboard.map((item:any)=>({
                ...item,
                customer:item.customer.name,
                // place:item.place.name,
            }));*/
            let reserversForDashboard = value.data.reserversForDashboard;

            this.dataSource = reserversForDashboard;
        });

        return newVar;
    }
}

const store = new Store();

const DateTimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 3) {
    return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    return (<span>{`${digit?.[1]}시  ${digit?.[2]}분`}</span>);

};


class _예약대시보드 extends Component<any> {

    mqtt = new MQTT((topic, message) => {
        store.load();
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        this.props.messageApi?.success("tag event");
    });

    componentDidMount() {
        store.load();
    }


    render() {
        return (
            <div css={css`
              //display: flex;
              //flex-direction: column;
              //align-items: stretch;
              height: 100vh;
              background: darkblue`
            }>
                <div css={css`padding: 2em`}>
                    <div css={css`text-align: center;
                      font-size: 3em;
                      color: white`}>예약
                    </div>

                    <Table
                        // css={css`background: #61dafb`}
                        className={"table_custom"}
                        dataSource={store.dataSource}
                        bordered
                        // title={() => "예약"}
                        pagination={false}
                        columns={[
                            {
                                title: "성명",
                                dataIndex: "name",
                                align: "center"
                            },
                            {
                                title: "예약시간",
                                dataIndex: "reserve_date",
                                render: (item) => (<span>{dayjs(item).format("HH시 : mm분")}</span>),
                                align: "center"
                            },
                            {
                                title: "입장",
                                dataIndex: "enter",
                                render: (item) => (<DateTimeFromString date={item}/>),
                                // render:(item:any)=>(<p>{JSON.stringify(item)}</p>)
                                // align: "center"
                            },
                            {
                                title: "시작",
                                dataIndex: "start",
                                render: (item) => (<DateTimeFromString date={item}/>),
                                align: "center"
                            },
                            {
                                title: "종료",
                                dataIndex: "end",
                                render: (item) => (<DateTimeFromString date={item}/>),
                                align: "center"
                            }
                        ]}

                    /></div>
            </div>
        );
    }
}

// observer()
export default WrapMessageAPI(observer(_예약대시보드));
