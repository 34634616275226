import React, {Component} from 'react';
import {ProTable} from "@ant-design/pro-components";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql, useQuery} from "@apollo/client";
import {ProColumns} from "@ant-design/pro-table/es/typing";

const columns: ProColumns<any, any>[] = [
    {
        title: "name",
        dataIndex: "name",
        hideInSearch: true
    },

    {
        title: "value",
        dataIndex: "value",
        valueType: "select",
        valueEnum: undefined

    },
];

const GQL = gql`
    query {
        info {
            name
            value
        }
    }
`;

const GQL_2 = gql`
    query {
        positions
    }
`

const FuncVerCompo = (props: any) => {
    let queryResult = useQuery(GQL_2);
    if (!queryResult.loading && !queryResult.error) {
        columns[1].valueEnum = (queryResult.data.positions as string[]).reduce((previousValue, currentValue) => {
            previousValue.set(currentValue, currentValue);
            return previousValue;
        },new Map())
    }

    console.log('data', queryResult.data);
    let {loading, error, data} = useQuery(GQL);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;


    return (
        <>
            {JSON.stringify(data)}
            <ProTable
                columns={columns}
                request={(params: any) => {
                    return new Promise((resolve, reject) => {
                        resolve({
                            total: data.info.length,
                            data: data.info,
                            success: true
                        })
                    })
                }}
            />
        </>

    )
}

class Exam3 extends Component {
    render() {
        return (
            <div>
                <FuncVerCompo/>
                <ProTable columns={columns}

                    request={async (param: any) => {
                        let value = await apollo_client.query({
                            query: GQL
                        });
                        let data = value.data.info;
                        return {
                            total: data.length,
                            data: data,
                            success: true
                        }
                    }}
                />
            </div>
        );
    }
}

export default Exam3;
