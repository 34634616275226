import {makeAutoObservable} from "mobx";
import Optional from "optional-js";
import _ from "lodash";
import {RepositoryGQL} from "./RepositoryGQL";
import {Item} from "./Item";
import {ID, IStore} from "../../antd_common/EditableTable";


function updateProp2<TObj, K extends keyof TObj>(obj: TObj, key: string, value: TObj[K]) {
    obj[key as K] = value;
}

export class Store implements IStore<Item> {
    recipe_id: Optional<number> = Optional.empty();

    obtainIndex(item: Item): Optional<number> {
        return this.obtainIndexFromID(item.id);
    }

    obtainIndexFromID(id: ID): Optional<number> {
        let find = this.dataSource
            .map((value, index, array) => ({index, value}))
            .find((value, index) => value.value.id == id)
        // .find((value, index1) => value.value.id == item.id);
        return Optional.ofNullable(find).map(value => value.index);
    }




    repository = new RepositoryGQL();

    dataSource: Item[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async create(new_item: Item & any): Promise<any> {
       /* new_item.id = uuidv4();
        // throw new Error("Method not implemented.");
        let items = _.cloneDeep(this.dataSource);

        items.unshift(new_item);
        this.dataSource = items;*/
        // return new Promise((resolve, reject) => reject());
        let a = await this.repository.create(new_item);
        this.load();
    }

    create_temp(new_item: Item): void {
        // throw new Error("Method not implemented.");
    }

    load() {
        // if(!this.recipe_id.isPresent())
        //     return new Promise((resolve, reject) => reject());

        return this.repository.load().then(value => {
            this.dataSource = value;
        })
        /*return new Promise<Item[]>((resolve, reject) => {
            resolve(this.dummyDataSource);
            // resolve(this.dataSource);
        }).then(value => {
            this.dataSource = value.map((value1, index) => ({...value1, index}));
        });*/
    }


    async update(item: Item, payload: Item) {

        let index = this.obtainIndexFromID(item.id);

        if(!Optional.ofNullable(index).isPresent())
            return;

        let item1 = _.cloneDeep(item);
        Object.entries(payload).forEach((value1, index1, array) => {
            let [key, value] = value1;
            updateProp2(item1, key, value);
            console.log(value1, index1);
        });

         await this.repository.update(item1);
        this.load();
    }

    async delete(id: ID) {
        await this.repository.delete(id);
        this.load();
    }
}

