import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import App from "./has/App/_근태관리";
import reportWebVitals from './reportWebVitals';
import App from "./has/App";


import { ConfigProvider } from 'antd';
// import jaJP from 'antd/lib/locale/ja_JP';
// import jaJP from 'antd/lib/locale/ja_JP';
// import locale from 'antd/lib/locale/ko_KR';
// import {profile} from "./has/BuildProfile";

// const locale2 = profile.locale;
// import locale from locale2;
import {apollo_client} from "./has/common/ApolloHelpper";
import {ApolloProvider} from "@apollo/client";
// import {locale} from "./has/locale/Locale";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// locale.strings.setLanguage('kr')

root.render(
    <React.StrictMode>
        {/*<ConfigProvider locale={locale}>*/}
            <ApolloProvider client={apollo_client}>
                <App/>
            </ApolloProvider>
        {/*</ConfigProvider>*/}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
