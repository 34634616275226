import {makeAutoObservable} from "mobx";
import Optional from "optional-js";
import axios from "axios";
import {gql} from "@apollo/client";
// import {apollo_client} from "../Comm";
import {Auth} from "./Auth";
import {apollo_client} from "../../common/ApolloHelpper";

class AppStore {
    auth_item_name = 'auth_token';
    auth_id = 'auth_id';
    auth_roles = 'roles';

    userName = '';

    // roles: string[] = [];

    set roles(roles: string[]) {
        localStorage.setItem('roles', JSON.stringify(roles));
    }

    get id() {
        return localStorage.getItem(this.auth_id);
    }

    get roles() {
        return Optional.ofNullable(localStorage.getItem('roles')).map(value => JSON.parse(value)).orElse([]);
    }

    async login(id: string, password: string) {
        let {data: token} = await axios.post("/api/login", {
            "id": id,
            "password": password
        });

        let AUTH_TOKEN = `Baerer ${token}`;
        localStorage.setItem(appStore.auth_item_name, AUTH_TOKEN);
        localStorage.setItem(appStore.auth_id, id);
        localStorage.setItem("auth_token", id);
        axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

        let {data} = await apollo_client.query({
            query: gql`
                query {
                    roles
                }
            `,
            fetchPolicy: "no-cache"
        });

        console.log(data.roles);
        appStore.roles = data.roles;


        return token;
    }


    async login2(id: string, password: string) {
        let data2 = await Auth.loginV2(id, password) as any;
        let {token, roles}: { token: string, roles: string[] } = data2.data.loginV2;
        // let AUTH_TOKEN = `Baerer ${token}`;
        // let AUTH_TOKEN = token;
        sessionStorage.setItem("auth_token", token);

        axios.defaults.headers.common['Authorization'] = token;

        return {token, roles};
    }

    logout() {
        localStorage.removeItem(this.auth_item_name)
        localStorage.removeItem(this.auth_id)
        localStorage.removeItem(this.auth_roles)
    }

    get hasAdmin(): boolean {
        let find = this.roles.find(value => value == 'ADMIN');
        console.log('find', find);
        return Optional.ofNullable(this.roles.find(value => value == 'ADMIN'))
            .map(value => true)
            .orElse(false);
    }


    hasRole(role: string): boolean {
        return Optional.ofNullable(this.roles.find(value => value == role))
            .map(value => true)
            .orElse(false);
    }


    constructor() {
        makeAutoObservable(this);

        console.log('AppStore 생성')
    }
}


export const appStore = new AppStore();
