import {useLocation, useParams} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import {useRef} from "react";

export const withRouter = (Component:any) => {
    const Wrapper = (props:any) => {
        const navigate = useNavigate();
        let params = useParams();
        let location = useLocation();

        return (
            <Component
                navigate={navigate}
                params={params}
                location={location}

                {...props}
            />
        );
    };

    return Wrapper;
};

export const withRef = (Component:any) => {
    return (props:any) => {
        const ref = useRef();
        return (
            <Component
                ref={ref}
                {...props}
            />
        );
    };
};
