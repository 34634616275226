import React, {Component} from 'react';
import {css} from "styled-components/macro";



const CompoList = ()=>{
    return (
        <div css={css`background: #6d9eff;display: flex;flex-direction: column`}>
            <button>test1</button>
            <button>test1</button>
            <button>test1</button>
        </div>
    );
}

const CompoDetail = ()=>{
    return (
        <div css={css`background: #61dafb;display: flex;flex-direction: column`}>
            <button>test2</button>
            <button>test3</button>
            <button>test4</button>
        </div>
    );
}

class ExamLayout extends Component {
    render() {
        return (
            <div css={css`display: flex;flex-direction: row;background: brown;height: 100vh`}>
                <CompoList/>
                <CompoDetail/>
            </div>
        );
    }
}

export default ExamLayout;
