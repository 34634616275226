import React, {Component} from 'react';
import {observer} from "mobx-react";
import {WrapMessageAPI} from "../../common/HocMessage";
import "../css/commonTable.css"
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {css} from "styled-components/macro";
import styled from "styled-components";
import icon_logo from "../../asset/seail hospital_logo.png";
import {columns_user, Item} from "./Item";
import EditableTable from "../../antd_common/EditableTable";
import {Store} from './Store';

const LogoImage = styled.img`
  display: flex;
  width: 180px;
  align-items: flex-end;
`;

const HeadTop = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
`

const HeadText = styled.p`
  text-align: center;
  font-size: 3em;
  font-weight: bold;;
`


/*class Store {

    data: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        return new Promise<any>((resolve, reject) => {
            this.data = data;
            resolve(data);
        });
    }
}*/

const store = new Store();

class _근태관리 extends Component<any> {

    /*  componentDidMount() {
          store.load();
      }*/

    render() {
        return (
            <div css={css`margin: 0 10em`}>
                <HeadText>MTS관리</HeadText>
                <HeadTop>
                    <LogoImage src={icon_logo}/>
                    <DateTimeIndicator/>
                </HeadTop>
                {/*<Table className={"table_custom3"}
                       pagination={{pageSize: 10, position: ["bottomCenter"]}}
                       dataSource={this.store.data}
                       columns={[
                           {
                               title: "NFC_UID",
                               dataIndex: "nfc_uid",
                               key: "nfc_uid",
                               // render: (item: any) => (<CvtDate date={item}/>),
                               align: "center",
                           },
                           {
                               title: "장소",
                               dataIndex: "place",
                               key: "place",
                               align: "center"

                           },
                           {
                               title: "이벤트",
                               dataIndex: "action",
                               key: "action",
                               align: "center"
                           }
                       ]}/>*/}
                <EditableTable<Item> store={store} columns_user={columns_user}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(_근태관리));
