import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from "@apollo/client";
import {onError} from "@apollo/client/link/error";

// const httpLink = new HttpLink({ uri: 'https://api.example.com/graphql' });
const httpLink = new HttpLink({ uri: '/graphql' });

const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    const token = sessionStorage.getItem('auth_token');

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
        headers: {
            // authorization: token ? `Bearer ${token}` : ''
            authorization: token
        }
    });

    // Call the next link in the middleware chain.
    return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError ,operation,forward}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path ,extensions}) => {
            switch (extensions.classification) {
                case 'FORBIDDEN':
                    break

                // case 'FORBIDDEN':
                //     break
            }
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
            },
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);


    // return forward(operation);
});

export const apollo_client = new ApolloClient({
    // uri: 'http://localhost:8080/graphql',
    // uri: '/graphql',
    // link:authLink.concat(httpLink),
    // link:httpLink,
    // link: from([authLink, errorLink,httpLink]),
    // link: from([errorLink,httpLink]),
    link: from([authLink, httpLink]),
    // link: from([httpLink]),
    cache: new InMemoryCache(),


});
/*
export const apollo_client_local = new ApolloClient({
    uri: 'http://localhost:8081/graphql',
    // uri: '/graphql',
    // link:authLink.concat(httpLink),
    cache: new InMemoryCache(),

});*/
