import LocalizedStrings, {LocalizedStringsMethods} from "react-localization";
import {profile} from "../BuildProfile";


export interface IStrings extends LocalizedStringsMethods {
    _출퇴근기록: string;
    _년월일: string;
    time: string;
    am: string;
    pm: string;
    _오늘: string;
    _일주일: string;
    _한달: string;
    _출입기간: string;
    _근태일시: string;
    _전화번호: string;
    _이름: string;
    _부서: string;
    _팀: string;
    _직책: string;
    _근태구분: string;
    _인증방법: string;
    _단말기: string;
    _물리치료: string;
    _도수치료: string;
    _입장시간: string;
    _치료시작: string;
    _치료종료: string;
    _엑셀다운로드: string;
}

class Locale {

    strings: IStrings = new LocalizedStrings({
        ko: {
            _출퇴근기록: "출퇴근기록",
            _년월일: "{year}년 {month}월 {day}일",
            am: "오전",
            pm: "오후",
            time: "{hours}시 {minutes}분",
            _오늘: "오늘",
            _일주일: "일주일",
            _한달: "한달",
            _출입기간: "출입기간",
            _근태일시: "근태일시",
            _전화번호: "전화번호",
            _이름: "이름",
            _부서: "부서",
            _팀: "팀",
            _직책: "직책",
            _근태구분: "근태구분",
            _인증방법: "인증방법",
            _단말기: "단말기",
            _물리치료: "물리치료실 대기자",
            _도수치료: "도수치료실 대기자",
            _입장시간: "입장시간",
            _치료시작: "치료시작",
            _치료종료: "치료종료",
            _엑셀다운로드: "엑셀다운로드",
        },
        jp: {
            _출퇴근기록: "出退勤記録",
            _년월일: "{year}年 {month}月 {day}日",
            am: "午前",
            pm: "午後",
            time: "{hours}時 {minutes}分",
            _오늘: "今日",
            _일주일: "一週間",
            _한달: "1ヶ月",
            _출입기간: "出入り期間",
            _근태일시: "勤怠日時",
            _전화번호: "電話番号",
            _이름: "名前",
            _부서: "部署",
            _팀: "チーム",
            _직책: "役職",
            _근태구분: "出退勤区分",
            _인증방법: "認証方法",
            _단말기: "端末",
            _물리치료: "物理治療室",
            _도수치료: "徒手としゅ",
            _입장시간: "入場時刻",
            _치료시작: "治療開始時刻",
            _치료종료: "治療終了時刻",
            _엑셀다운로드: "Excelのダウンロード",
        }
    });


    constructor() {
        this.strings.setLanguage(profile.language_pack);
        // this.strings.setLanguage('ko');
    }
}


export const locale = new Locale();
