import React, {Component} from 'react';
import _대기자대시보드 from "./_대기자대시보드";
import {locale} from "../../locale/Locale";

const strings = locale.strings;
class _도수센터 extends Component {
    render() {
        return (
            <div>
                <_대기자대시보드 topic={"nfc/도수센터/#"} title={strings._도수치료} place={"도수센터"}/>
            </div>
        );
    }
}

export default _도수센터;
