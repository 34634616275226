import React, {Component} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import _근태관리 from "./App/근태관리/_근태관리";
import _예약대시보드 from "./App/대시보드예약n진행상황/_예약대시보드";
import _예약관리 from "./App/예약관리/_예약관리";
import _예약대시보드V2 from "./App/대시보드예약n진행상황/_예약대시보드V2";
import {css} from 'styled-components/macro';
import _대기자대시보드 from "./App/대기자대시보드/_대기자대시보드";
import Table로그인사용자 from "./App/이벤트로그/Table로그인사용자";
import Table비로그인사용자 from "./App/이벤트로그/Table비로그인사용자";
import MQttExam from "./App/Experiment/MQttExam";
import _물리치료실 from "./App/대기자대시보드/_물리치료실";
import _도수센터 from "./App/대기자대시보드/_도수센터";
import Login from "./libs/auth/Login";
import _근태관리2 from './App/근태관리/_근태관리2';
import Blockchain from "./App/블럭체인/_근태관리2";
import _NFC태그관리2 from "./App/NFC태그관리/NFC태그관리"
import _근태관리3 from './App/근태관리/_근태관리3';
import Info from "./App/Info/Info";
import Exam3 from "./App/근태관리/Exam3";
import Noti from "./App/접수/Noti";
import Board from "./App/Board/Board";
import BoardAdmin from './App/BoardAdminV2/BoardAdmin';
import ExamLayout from "./App/Experiment/ExamLayout";
import {profile} from "./BuildProfile";
import {ConfigProvider} from "antd";
import _순찰 from './App/순찰/대시보드/_순찰';
import Monitor from "./App/모니터링/Monitor";

class App extends Component {
    render() {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <BrowserRouter>
                <Routes>
                    {/*<Route path={"/"} element={<Links/>}/>*/}
                    <Route path={"/"} element={<Login/>}/>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/board"} element={<Board/>}/>
                    {/*<Route path={"/board_admin"} element={<BoardAdmin/>}/>*/}
                    <Route path={"/board_admin"} element={<BoardAdmin/>}/>
                    <Route path={"/monitor"} element={<Monitor/>}/>
                    <Route path={"/info"} element={<Info/>}/>
                    <Route path={"/exam"} element={<Exam3/>}/>
                    <Route path={"/examlayout"} element={<ExamLayout/>}/>
                    <Route path={"/reception"} element={<Noti/>}/>
                    <Route path={"/attendance"} element={<_근태관리/>}/>
                    <Route path={"/attendance2"} element={<_근태관리2/>}/>
                    <Route path={"/attendance3"} element={<_근태관리3/>}/>
                    <Route path={"/patrol"} element={<_순찰/>}/>
                    <Route path={"/nfcadmin"} element={<_NFC태그관리2/>}/>
                    <Route path={"/mtsadmin"} element={<_NFC태그관리2/>}/>
                    <Route path={"/event_logs_employee"} element={<Table로그인사용자/>}/>
                    <Route path={"/event_logs_consumer"} element={<Table비로그인사용자/>}/>

                    <Route path={"/dashboard1"} element={<_물리치료실/>}/>
                    <Route path={"/dashboard2"} element={<_도수센터/>}/>
                    <Route path={"/blockchain"} element={<Blockchain/>}/>

                    <Route path={"/reserve"} element={<_예약관리/>}/>
                    <Route path={"/reserve_dash_board"} element={<_예약대시보드/>}/>
                    <Route path={"/reserve_dash_board2"} element={<_예약대시보드V2/>}/>
                    <Route path={"/waitlist"} element={<_대기자대시보드/>}/>
                    <Route path={"/mqtt"} element={<MQttExam/>}/>
                    <Route path={"/dev"} element={
                        <div css={css`
                          background: #6d9eff;
                          //display: flex;
                          //flex-direction: column;
                          //align-items: center;
                          height: 100vh;
                        `}>
                            <div css={css`
                              //width: 100px;
                              //height: 100px;
                              background: bisque;
                              border: #282c34 solid 1px`}>
                                Hello
                            </div>

                            <div css={css`
                              //width: 100px;
                              //height: 100px;
                              background: bisque;
                              border: #282c34 solid 1px`}>
                                Hello
                            </div>
                        </div>
                    }/>
                    <Route path={"*"} element={<span>404</span>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

class Wrap extends Component {
    // state = {locale_kr};
    // state = {locale:locale_jp};
    state = {locale: null};

    componentDidMount() {
        // import(profile.import_path).then(value => {
        // import("antd/lib/locale/ja_JP").then(value => {
        /*import("antd/lib/locale/ko_KR").then(value => {
        // import("antd/lib/locale/ko_KR").then(value => {
            console.log('***', JSON.stringify(value));
            this.setState({locale:value})
        })*/
    }

    render() {
        /* if(this.state.locale == null)
             return(
                 <div>
                     로딩중...
                 </div>
             )*/
        return (
            <div>
                {/*<ConfigProvider locale={this.state.locale}>*/}
                <ConfigProvider locale={profile.locale}>
                    <App/>
                </ConfigProvider>
            </div>
        );
    }
}

export default Wrap;
