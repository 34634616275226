import React, {Component} from 'react';
import {Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useForm} from "antd/es/form/Form";
import {TypePost} from "../Board/TypePost";

const  PostForm = (props: { item?: TypePost })=> {
    // form?:Form = null
    const [form] = useForm<TypePost>();

    if(props.item == null)
        return (<></>);

    form.setFieldsValue({
        title:props.item?.title,
        content:props.item?.content,
    })

        return (
            <Form form={form}>
                {/*<p>title</p>*/}
                <Form.Item name={"title"} label={"title"}>
                    <Input/>
                </Form.Item>
                {/*<p>content</p>*/}
                <Form.Item name={"content"} label={"content"}>
                    <TextArea autoSize/>
                </Form.Item>
            </Form>
        );

}

export default PostForm;
