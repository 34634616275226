import React, {Component} from 'react';

class Info extends Component {
    render() {
        return (
            <div>
                Hello!
            </div>
        );
    }
}

export default Info;
