import React, {Component} from 'react';
import { css } from 'styled-components/macro';
import _대기자대시보드 from "./_대기자대시보드";
import {locale} from "../../locale/Locale";

const strings = locale.strings;
class _물리치료실 extends Component {
    render() {
        return (
            <div >
                <_대기자대시보드 topic={"nfc/물리치료실/#"} title={strings._물리치료} place={"물리치료실"}/>
            </div>
        );
    }
}

export default _물리치료실;
