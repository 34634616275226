import React, {Component} from 'react';
import {Table} from "antd";
import {makeAutoObservable} from "mobx";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {observer} from "mobx-react";
import {MQTT} from "../../common/MQTT";
import {WrapMessageAPI} from "../../common/HocMessage";
import Optional from "optional-js";



const DateTimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일 ${digit?.[4]}시  ${digit?.[5]}분`}</span>);
};

const DateFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    // return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일 ${digit?.[4]}시  ${digit?.[5]}분`}</span>);
    return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일`}</span>);
};

const TimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    return (<span>{`${digit?.[4]}시  ${digit?.[5]}분`}</span>);
};
class Store {

    data = [];


    constructor() {
        makeAutoObservable(this);
    }

    async load() {

        let newVar = await apollo_client.query({
            query: gql`
                query {
                    attendance {
                        id
                        place
                        action
                        action_date
                        createdDate
                        eventOwner {
                            userId
                            name
                            phone
                            sn
                        }
                    }
                }
            `
            , fetchPolicy: "no-cache"
        }).then(value => {

            // console.log('value.data.attendance',value.data.attendance)
                let data1 = value.data.attendance.filter((item:any)=>item.eventOwner != null).map((item: any) => {
                    return {...item, userId:item.eventOwner.userId,name: item.eventOwner.name, phone: item.eventOwner.phone, sn: item.eventOwner.sn}
                });
                this.data = data1
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
        });

        return newVar;
    }
}

class _근태관리 extends Component<any> {
    store = new Store();


    mqtt = new MQTT((topic, message) => {
        this.store.load();
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        // this.props.messageApi?.success("Hello!");
    });


    componentDidMount() {
        this.store.load();
    }

    componentWillUnmount() {
        this.mqtt.close();
    }

    render() {
        return (
            <div>

                <Table dataSource={this.store.data} columns={[
                    {
                        title: "id",
                        dataIndex: "id",
                        key: "id",

                    },
                    {
                        title: "place",
                        dataIndex: "place",
                        key: "place",
                    },
                    {
                        title: "action",
                        dataIndex: "action",
                        key: "action",
                    },
                    {
                        title: "userId",
                        dataIndex: "userId",
                        key: "userId",
                    },
                    {
                        title: "이름",
                        dataIndex: "name",
                        key: "이름",
                    },
                    {
                        title: "전화번호",
                        dataIndex: "phone",
                        key: "phone",
                    },
                    {
                        title: "날짜",
                        dataIndex: "action_date",
                        key: "action_date1",
                        render: (item: any) => (<DateFromString date={item}/>),
                    },
                    {
                        title: "시간",
                        dataIndex: "action_date",
                        key: "action_date2",
                        render: (item: any) => (<TimeFromString date={item}/>),
                    }

                ]}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(_근태관리));
