import locale_kr from "antd/lib/locale/ko_KR";
import locale_jp from 'antd/lib/locale/ja_JP';
import {Locale} from "antd/lib/locale";

// import logo_medinomi from './asset/medinomi_logo_blue.png'
import logo_medinomi from './asset/medinomi_logo_yellow.png'
import sail_logo from "./asset/seail hospital_logo.png";
import logo_spera from './asset/spera_logo.png'



// import {locale} from "./locale/Locale";

export enum TypeLocale {
    _한국어 = 'ko', _일본어 = 'jp'
}

enum TypeProfile {
    _표준데모,_세일, _스페라
}


interface Profile {
    //antd 로케일
    locale: Locale;
    //언어팩
    language_pack: TypeLocale;
    //로고세트
    logo?: any
    // import_path:string

    back_end_ip?: string
}

export class BuildProfile {

    static obtainProfile(domain: TypeProfile): Profile {
        switch (domain) {
            case TypeProfile._세일:
                return {
                    language_pack: TypeLocale._한국어,
                    locale: locale_kr,
                    logo: sail_logo,
                }
                break;


            case TypeProfile._스페라:
                return {
                    language_pack: TypeLocale._일본어,
                    locale: locale_jp,
                    logo: logo_spera,

                }
                break

            case TypeProfile._표준데모:
                return {
                    language_pack: TypeLocale._한국어,
                    locale: locale_kr,
                    logo: logo_medinomi,

                }
                break


            default:
                throw new Error("NotImplementedError")
                break;

        }
    }
}

// export const profile = BuildProfile.obtainProfile(TypeProfile._스페라)
export const profile = BuildProfile.obtainProfile(TypeProfile._세일)
// export const profile = BuildProfile.obtainProfile(TypeProfile._표준데모)
