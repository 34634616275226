import React, {Component} from 'react';
import { Button, Dropdown, Space, Tag } from 'antd';
import {makeAutoObservable} from "mobx";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {observer} from "mobx-react";
import {MQTT} from "../../common/MQTT";
import {WrapMessageAPI} from "../../common/HocMessage";
import Optional from "optional-js";
import "./table.css"
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {ColumnsType} from "antd/es/table";
import {css} from "styled-components/macro";
import styled from "styled-components";
import icon_logo from "../../asset/seail hospital_logo.png";
import { ProTable, TableDropdown } from '@ant-design/pro-components';
import type { ActionType, ProColumns } from '@ant-design/pro-components';

const LogoImage = styled.img`
  display: inline-block;
  width: 200px;
  height: 80px;
  align-items: center;
`;

const HeadTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`

const HeadText = styled.p`
  text-align: center;
  font-size: 3em;
  font-weight: bold;
`

const CvtDate = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    return (<span>{`${digit?.[1]}-${digit?.[2]}-${digit?.[3]} ${digit?.[4]}:${digit?.[5]}:${digit?.[6]}`}</span>);
};

const DateFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    // return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일 ${digit?.[4]}시  ${digit?.[5]}분`}</span>);
    return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일`}</span>);
};

const TimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    return (<span>{`${digit?.[4]}시  ${digit?.[5]}분`}</span>);
};

class Store {

    data = [];


    constructor() {
        makeAutoObservable(this);
    }

    async load() {

        let newVar = await apollo_client.query({
            query: gql`
                query {
                    attendance {
                        id
                        place
                        action
                        action_date
                        createdDate
                        eventOwner {
                            userId
                            name
                            phone
                            sn
                            department
                            team
                            position
                            authentication_method
                        }
                    }
                }
            `
            , fetchPolicy: "no-cache"
        }).then(value => {
                let data1 = value.data.attendance.map((item: any) => {
                    /*return {...item, userId:item.eventOwner.userId,name: item.eventOwner.name, phone: item.eventOwner.phone, sn: item.eventOwner.sn,
                        department:item.eventOwner.department,team:item.eventOwner.team,position:item.eventOwner.position,authentication_method:item.eventOwner.authentication_method,

                    }*/

                    return {...item, ...item.eventOwner}
                });
                this.data = data1
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
        });

        return newVar;
    }
}


class _근태관리 extends Component<any> {
    store = new Store();


    mqtt = new MQTT((topic, message) => {
        setTimeout(args => {
            this.store.load();
        }, 5000);
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        // this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        this.props.messageApi?.success(`${parse._성명} 블럭체인 트랜젝션, 마이닝 중입니다.`);
        // this.props.messageApi?.success("Hello!");
    });


    componentDidMount() {
        this.store.load();
    }

    componentWillUnmount() {
        this.mqtt.close();
    }

    render() {
        return (
            <div css={css`margin: 0 10em`}>
                <HeadText>출퇴근기록</HeadText>
                <HeadTop>
                    <LogoImage src={icon_logo}/>
                    <DateTimeIndicator/>
                </HeadTop>

                <ProTable className={"table_custom3"}
                          pagination={{pageSize: 10, position: ["bottomCenter"]}}
                          dataSource={this.store.data}
                          columns={[
                              {
                                  title: "근태일시",
                                  dataIndex: "action_date",
                                  key: "action_date1",
                                  align: "center",
                                  sorter: true,
                                  // valueType: "dateTimeRange",
                                  // initialValue: [Date.now(), Date.now()],
                              },
                              {
                                  title: "등록번호",
                                  dataIndex: "id",
                                  key: "id",
                                  align: "center",
                                  hideInSearch: true,
                              },
                              {
                                  title: "이름",
                                  dataIndex: "name",
                                  key: "이름",
                                  align: "center"
                              },
                              {
                                  title: "부서",
                                  dataIndex: "department",
                                  key: "부서",
                                  align: "center",
                                  valueType: 'select',
                                  valueEnum: {
                                      open: {
                                          text: '총무과',
                                      },
                                      closed: {
                                          text: '간호과',
                                      }
                                  }

                              },
                              {
                                  title: "팀",
                                  dataIndex: "team",
                                  key: "팀",
                                  align: "center"
                              },
                              {
                                  title: "직책",
                                  dataIndex: "position",
                                  key: "직책",
                                  align: "center",
                                  valueType: 'select',
                                  valueEnum: {
                                      open: {
                                          text: '실장',
                                      },
                                      closed: {
                                          text: '과장',
                                      }
                                  }

                              },
                              {
                                  title: "근태구분",
                                  dataIndex: "action",
                                  key: "근태구분",
                                  align: "center"
                              },
                              {
                                  title: "인증방법",
                                  dataIndex: "authentication_method",
                                  key: "인증방법",
                                  align: "center"
                              },
                              {
                                  title: "단말기",
                                  dataIndex: "place",
                                  key: "단말기",
                                  align: "center"
                              },
                          ]}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(_근태관리));
