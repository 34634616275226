import {ComponentType} from "react";
import {message} from "antd";

export function WrapMessageAPI(WrapComponent: ComponentType<any>) {

    return (props:any) => {
        const [messageApi, contextHolder] = message.useMessage();

        const success = (content:string = 'This is a success message') => {
            messageApi.open({
                type: 'success',
                content
            });
        };

        const error = (content:string = 'This is a error message') => {
            messageApi.open({
                type: 'error',
                content
            });
        };

        const warning = (content:string = 'This is a warning message') => {
            messageApi.open({
                type: 'warning',
                content
            });
        };

        return (
            <>{contextHolder}
                <WrapComponent {...props} messageApi={{success,error,warning}} />
            </>
        )
    }

}
