import React, {Component} from 'react';
import {Button, Table} from "antd";
import {makeAutoObservable} from "mobx";
import {apollo_client} from "../../common/ApolloHelpper";
import {ApolloError, gql} from "@apollo/client";
import {observer} from "mobx-react";
import {MQTT} from "../../common/MQTT";
import {WrapMessageAPI} from "../../common/HocMessage";
import Optional from "optional-js";
// import "./table.css"
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {css} from "styled-components/macro";
import styled from "styled-components";
import icon_logo from "../../asset/seail hospital_logo.png";
import {init_data} from "./Data";
import {Chain} from "./root";
import _ from "lodash";
import {Subject, Subscription} from "rxjs";
import {withRouter} from "../../common/UtilCompo";
import {LogoChange} from "../../common/LogoChange";
import {locale} from "../../locale/Locale";

const strings = locale.strings;
const LogoImage = styled.img`
  display: inline-block;
  width: 200px;
  height: 80px;
  align-items: center;
`;

const LogoBox = styled.div`
  width: 200px;
  height: auto;
  align-items: center;
`;

const HeadTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;;
`

const HeadText = styled.p`
  text-align: center;
  font-size: 3em;
  font-weight: bold;;
`

const CvtDate = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    return (<span>{`${digit?.[1]}-${digit?.[2]}-${digit?.[3]} ${digit?.[4]}:${digit?.[5]}:${digit?.[6]}`}</span>);
};

const DateFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    // return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일 ${digit?.[4]}시  ${digit?.[5]}분`}</span>);
    return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일`}</span>);
};

const TimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    return (<span>{`${digit?.[4]}시  ${digit?.[5]}분`}</span>);
};

class Store {

    data = {chain:[]};
    subject = new Subject<string>();

    constructor() {
        makeAutoObservable(this);
    }

    async load() {

        /*  return new Promise<Root>(resolve => {
              resolve(init_data);
          })*/
        let [newVar] = await Promise.all([apollo_client.query({
            query: gql`
                query {
                    chain
                }
            `
            , fetchPolicy: "no-cache"
        }).then(value => {

                let data1 = JSON.parse(value.data.chain);

                this.data = data1;
                let cloneDeep = _.cloneDeep(data1);
                let test = cloneDeep.chain.sort((a: Chain, b: Chain) => {
                    let number = b.index - a.index;
                    console.log('number', number);
                    return number;
                });
                this.data.chain = test;
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
            let e = reason;
            console.log(e);
            let error = e as ApolloError;
            if (error.message == 'Forbidden') {
                console.log("403")
                this.subject.next("403");
            }

            if (error.message == 'Unauthorized') {
                console.log("403")
                this.subject.next("401");
            }

        })]);

        return newVar;
    }
}


class Blockchain extends Component<any> {
    store = new Store();


    mqtt = new MQTT((topic, message) => {
        this.store.load();
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        // this.props.messageApi?.success("Hello!");
    });

    subscription?: Subscription;


    componentDidMount() {
        this.store.load();

        this.subscription = this.store.subject.subscribe(value => {
            console.log('error', value)

            switch (value) {
                case "401":
                case "403":
                    this.props.messageApi?.error(`${value} 로그인페이지로 이동합니다.`);
                    setTimeout(args => {
                        this.props.navigate("/login"); //로그인
                    }, 2000)
                    break;

                default:
                    break;

            }
        });

    }

    componentWillUnmount() {
        this.mqtt.close();
    }

    render() {
        return (
            <div css={css`margin: 0 10em`}>
                <HeadText>Block Chain_Dashboard for MediNomi</HeadText>
                <HeadTop>
                    <LogoBox>
                        <LogoChange />
                    </LogoBox>
                    <DateTimeIndicator/>
                </HeadTop>
                {/*<Button onClick={()=>{*/}
                {/*    strings.setLanguage('ko');*/}
                {/*    this.setState({});*/}
                {/*}}>Ko</Button>*/}
                {/*<Button onClick={()=>{*/}
                {/*    strings.setLanguage('jp');*/}
                {/*    this.setState({});*/}
                {/*}}>Jp</Button>*/}
                <Table className={"table_custom3"}
                       pagination={{pageSize: 10, position: ["bottomCenter"]}}
                       dataSource={this.store.data.chain}
                       columns={[
                           /*{
                               title: "Block번호",
                               dataIndex: "action_date",
                               key: "action_date1",
                               render: (item: any) => (<CvtDate date={item}/>),
                               align: "center",
                           },*/
                           {
                               title: "Block Number",
                               dataIndex: "index",
                               key: "index",
                               // render: (item: any) => (<CvtDate date={item}/>),
                               align: "center",
                               width: "10%"
                           },
                           {
                               title: "Hash",
                               width: "30%",

                               dataIndex: "previous_hash",
                               key: "previous_hash",
                               align: "center",
                               render: (item: any) => (
                                   <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                                       {item}
                                   </div>),

                           },
                           {
                               title: "Transaction",
                               width: "60%",
                               dataIndex: "transactions",
                               render: (item: any) => (<span>{JSON.stringify(item)}</span>),
                               key: "transactions",
                               align: "center"
                           }
                       ]}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
// withRouter()
export default withRouter(WrapMessageAPI(observer(Blockchain)));
