import React, {Component} from "react";
import {gql} from "@apollo/client";
import {DatePicker, Form, Input, Modal, Radio, Space, TimePicker} from "antd";
import dayjs from "dayjs";
import { apollo_client } from "../../common/ApolloHelpper";

export class AddItemModal extends Component<{ cb: (fields: any) => void }, any> {
    state = {show: false, data: []};
    show = () => {
        this.setState({show: true})
    }

    hide = () => {
        this.setState({show: false})
    }


    handleOK = () => {
        this.hide();
        let fieldsValue = this.form.getFieldsValue();
        console.log('fieldsValue', fieldsValue);
        this.props.cb(fieldsValue);
    }

    handleCancel = () => {
        this.hide();
    }

    form: any = null;

    componentDidMount() {
        apollo_client.query({
            query: gql`
                query {
                    eventowners{
                        id
                        name
                        phone

                    }
                }
            `,
            fetchPolicy: "no-cache"
        }).then(value => {
            console.log(value.data.eventowners);
            this.setState({data: value.data.eventowners})
        })
    }

    render() {
        return (
            <Modal open={this.state.show}
                   onOk={this.handleOK}
                   onCancel={this.handleCancel}
                   okText={"확인"}
                   cancelText={"취소"}
            >
                {/*{this.state.ingredients.map((value: any) => (<Button>{value.name}</Button>))}*/}
                <Form
                    labelCol={{span: 8}}
                    initialValues={{date: dayjs()}}
                    ref={ref => this.form = ref} style={{width: '20em'}}>

                    <Form.Item name={'ingredient'}><Radio.Group>
                        <Space wrap>
                            {this.state.data.map((value: any) => (
                                <Radio.Button value={value}>{value.name} : {value.phone}</Radio.Button>)
                            )}
                        </Space>
                    </Radio.Group>
                    </Form.Item>

                    <Form.Item label={"예약일"} name={'date'}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item label={"예약시간"} name={'time'}>
                        <TimePicker />
                    </Form.Item>

                </Form>
            </Modal>
        );
    }
}
