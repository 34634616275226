import React from "react";
import {toJS} from "mobx";
import {BaseItem} from "../../antd_common/EditableTable";

export interface Item extends BaseItem{

    // 필수
    // index?: number
    // id: string | number
    // key?: number | string;

    isSelectedItem?: () => boolean
    //사용자 정의
    name: string;
    phone: string;
    place: string;
    date:string

}


export const columns_user = [
    {
        title: "id",
        dataIndex: "id",
        editable: false,
        // defaultSortOrder: 'descend',
        // sorter: (a:any, b:any) => a.id - b.id
    },
    {
        title: "성명",
        dataIndex: "name",
        editable: false,
        // render:(item:any)=><span>{item.name}</span>,
        defaultSortOrder: 'descend',
        sorter: (ra:any, rb:any) => {
            console.log(toJS(ra), toJS(rb));
            let a = ra.customer.name;
            let b = rb.customer.name;

            if(a <  b)
                return -1;
            if(a >  b)
                return 1;
            return 0;
        }

    },
    {
        title: "전화번호",
        dataIndex: "phone",
        editable: true
    },
    {
        title: "예약부서",
        dataIndex: "place",
        editable: true
    },
    {
        title: "예약일시",
        dataIndex: "date",
        editable: true
    }
];
