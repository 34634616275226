import React, {Component, useEffect, useRef, useState} from 'react';
import Board from "../Board/Board";
import {TypePost} from "../Board/TypePost";
import {Button, Drawer, Form, Input} from "antd";
import {RepoPost} from "../Board/RepoPost";
import {css} from "styled-components/macro";
import TextArea from "antd/es/input/TextArea";
import {useForm} from "antd/es/form/Form";
import PostForm from "./PostForm";


const repo = new RepoPost();

const PostItem = (props: { item: TypePost, cb: (item: TypePost) => void }) => {
    return (
        <div css={css`display: flex;
          flex-direction: column;
          padding: .2em`}>

            <button onClick={() => {
                props.cb(props.item);
            }}>
                {props.item.id} {props.item.createdDate} {props.item.title}
            </button>
        </div>
    )
}

const PostDetail = (props: { item?: TypePost }) => {

    return (
        <div css={css`background: cadetblue;
          display: flex;
          flex-direction: column`}>



            <PostForm item={props.item}/>

            <div css={css`display: flex;flex-direction: row;justify-content: space-between`}>
                <button>Write</button>
                <button>Delete</button>
            </div>
        </div>
    )
}

const PostList = (props: { list: TypePost[], cb: (item: TypePost) => void }) => {
    const [open, setOpen] = useState(false);

    const [item, setItem] = useState<TypePost>()
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div css={css`background: brown;
          display: flex;
          flex-direction: column`}>
            {props.list.map(value => <PostItem item={value} cb={(item: TypePost) => {
                setItem(item);
                props.cb(item);
                // showDrawer();
            }}/>)}

        </div>
    )
}

class BoardAdmin extends Component {

    state: { posts: TypePost[], current?: TypePost } = {posts: []}

    componentDidMount() {
        repo.fetchPost().then(value => {
            this.setState({posts: value})
            console.log(value);
        })

    }

    render() {
        return (
            <div css={css`display: flex;
              flex-direction: row;
              background: #6d9eff;
              height: 100vh`}>
                <div>
                    <button>Add Notification</button>
                    <PostList list={this.state.posts}
                               cb={(item) => {
                                   this.setState({current: item})
                               }}
                />
                </div>
                <PostDetail item={this.state.current}/>
            </div>
        );
    }
}

export default BoardAdmin;
