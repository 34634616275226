import React, {Component} from 'react';
import EditableTable from "../../antd_common/EditableTable";
import {AddItemModal} from './AddItemModal';
import {columns_user, Item} from "./Item";
import {Store} from "./Store";

const store = new Store();

class _예약관리 extends Component {
    private modal: any;
    render() {
        return (
            <div>
                <EditableTable<Item>
                    store={store}
                    columns_user={columns_user}
                    onAddItem={() => {
                        this.modal.show();
                    }} addName={'예약추가'}
                />

                <AddItemModal
                    cb={(payload: any) => {
                        console.log(JSON.stringify(payload));
                        store.create(payload);
                    }}
                    ref={ref => this.modal = ref}/>

            </div>
        );
    }
}

export default _예약관리;
