
import {gql} from "@apollo/client";
import {BaseItem, ID} from "../../antd_common/EditableTable";
import { apollo_client } from "../../common/ApolloHelpper";

export class RepositoryGQL<T extends BaseItem> {
    public async load() {
        try {
            let value = await apollo_client.query({
                query: gql`
                    query {
                        reservers {
                            id
                            customer {
                                name
                                phone
                            }
                            place
                            date
                        }
                    }
                `
                ,
                fetchPolicy: "no-cache"
            })
            let data = value.data.reservers;
            data = data.map((item: any) => ({...item, ...item.customer,}));
            console.log('reservers',data);
            return data;
        } catch (e) {
            return null;
        }
    };

    public async create(payload:any) {
        return new Promise((resolve, reject) => reject())
       /* let {recipe_id, ingredient_id, requirement} = payload;
        let newVar = await apollo_client.mutate({
            mutation: gql`
                mutation create_ingredient_requirement($recipe_id:ID!,$ingredient_id:ID!,$requirement:Int){
                    create_ingredient_requirement(recipe_id:$recipe_id,ingredient_id:$ingredient_id,requirement: $requirement ){
                        id
                        ingredient{
                            name
                        }
                        requirements
                    }
                }
            `,
            variables: {
                recipe_id,
                ingredient_id,
                requirement,
            }
        });*/

    }

    public async update(input:T) {

        return new Promise((resolve, reject) => reject())

        /*console.log('inout', input);
        console.log('inout', input.id);
        let assign = Object.assign(input);
        let id = input.id;
        delete assign['id'];
        delete assign['__typename'];
        console.log('inout2', assign);

        let payload = {id,requirements:assign.requirements}
        // return new Promise((resolve, reject) => reject());

        let ret = await apollo_client.mutate({mutation:gql`
                mutation update_ingredient_requirement($id:ID!,$requirements:Int!){
                    update_ingredient_requirement(id:$id,requirements: $requirements){
                        id
                        requirements
                    }
                }
            `,
            variables:{id,requirements:assign.requirements}
            ,
            fetchPolicy:"no-cache"
        })*/





    }

    public async delete(id:ID) {
        return new Promise((resolve, reject) => reject())
       /* await apollo_client.mutate({
            mutation: gql`
                mutation delete_ingredient_requirement($id:ID!){
                    delete_ingredient_requirement(id:$id)
                }
            `,
            variables: {
                id
            }
        });*/
    }
}
