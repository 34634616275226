import mqtt from 'precompiled-mqtt';
import {Subject} from "rxjs";
import {Predef} from "./Predef";

interface IOUT {
    onMessage(topic: string, message: any): void;
}

/*interface Action {
    exec(iout: IOUT): void;
}*/
type Action = (iout: IOUT) => void
type T_EmittItem = (topic: string, message: any) => void

export class MQTT {
    // client = mqtt.connect('mqtt://3.35.27.197:15675/ws',{
    client = mqtt.connect(`mqtt://${Predef.MQTT_IP}:15675/ws`, {
        username: 'user',
        password: '0000'
    });
    subject: Subject<Action>;

    topic = "nfc/#";

    constructor(cb: (topic: string, message: any) => void,topic_param?:string) {
        let final_topic = topic_param ?? "nfc/#"
        console.log('생성');
        this.subject = new Subject<Action>();

        let client = this.client;
        let that = this;
        client.on('disconnect',function () {
            console.log('disconnect')
        })
        client.on('connect', function () {
            // let topic1 = `mmz_hmi/${domain_id}/${cluster_id}/${conteroler_id}`;
            // let topic2 = `mmz_hmi/${domain_id}/${cluster_id}/${conteroler_id}/+`;
            console.log('connect')
            let topic =  final_topic
            client.subscribe(topic, function (err) {
                // client.subscribe(`#`, function (err) {
                console.log('mqtt connected!');
                /*if (!err) {
                    client.publish('presence', 'Hello mqtt')
                }*/
            })

            /*client.subscribe(topic2, function (err) {
                // client.subscribe(`#`, function (err) {
                console.log('mqtt connected!');
                /!*if (!err) {
                    client.publish('presence', 'Hello mqtt')
                }*!/
            })*/

        })

        client.on('message', function (topic, message) {
            // message is Buffer
            console.log('topic*', topic)
            console.log('message', message.toString())
            cb(topic, message);

            // that.subject.next((iout: IOUT) => iout.onMessage(topic, message));
            that.subject.next((iout: IOUT) => iout.onMessage(topic, message));
            // client.end()

        })
    }

    close = ()=>{
        // this.client.unsubscribe('/nfc/#');
    }
}
