import {TypePost} from "./TypePost";
import {post_example} from "./PostExample";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";

export class RepoPost {
    fetchPost(){
        // return this.fetchPostExample();
        return this.fetchPostRemote();
    }

    async fetchPostRemote(){
        let data = await apollo_client.query({
            query: gql`
                query posts{
                    posts {
                        id,
                        title,
                        content
                        createdDate
                    }
                }
            `,
            fetchPolicy: "no-cache"
        });
        console.log(data.data.posts);
        return data.data.posts;
    }

    fetchPostExample(){
        return new Promise<TypePost[]>((resolve, reject) => {
            resolve(post_example);
        })
    }

}
